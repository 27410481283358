import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, List, ListItem, TextField } from "@mui/material";
import { useState } from 'react';
import {  getsettingsdata, updatesetting } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';


const PayoutSettings = () => {
    const [levelbonusdata, setLevelBonusData] = useState('');
    const [isloading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({

    });
    const GetSettingsData = () => {
        let params = {
            keynames : "'minwithdrawal'"
        }

        getsettingsdata(params,(response) => {
            setLevelBonusData(response?.data?.data?.bonus)
            setIsLoading(false);
            
            const initialData = {};
            response?.data?.data?.bonus.forEach((item) => {
              initialData[item.keyname] = item.keyvalue;
            });
            setInitialValues(initialData);
          
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    useEffect(() => {
        GetSettingsData();
    }, []);


    const validationSchema = yup.object({
        minwithdrawal: yup
            .number()
            .required('Amount  is required'),
    });


    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "black"
            }
        }
    }


const handleSubmit = (data, setSubmitting) =>{
    let params = {
        obj : data
    }
    updatesetting(params, (response) => {
        
        if( response?.data?.status === "error"){
          setalertData({
            show:true,
            message:response?.data?.message,
            variant:"error"
        }) 
            setSubmitting(false)
        }else if(response?.data?.status === "success"){
  
          setalertData({
            show:true,
            message:response?.data?.message,
            variant:"success"
        })
            setSubmitting(false)
            // resetForm();
  
        }
       
    }, (error) => {
        console.log(error?.response?.data);
    })
}

    if (isloading) {
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        <JumboCardQuick title={"Payout Settings"} noWrapper sx={{maxWidth: '100%', width: 360, mb: 4}}>

            {
                alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
            }
            <List disablePadding sx={{ mb: 2 }}>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        handleSubmit(data, setSubmitting);
                    }}
                >

                    {({ isSubmitting }) => (
                        <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                            {levelbonusdata?.map((data, i) => (
                                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }} key={i}>
                                    <JumboTextField
                                        fullWidth
                                        name={data.keyname}
                                        label={'Minimum Payout Amount'}
                                        defaultValue={data?.keyvalue}
                                        type="number"
                                    />
                                </Div>
                            )
                            )}


                            <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >Update</LoadingButton>
                            </Div>



                        </Form>
                    )}
                </Formik>

            </List>
        </JumboCardQuick>
        </div>
    );
};

export default PayoutSettings;
