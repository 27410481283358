import React, { useContext, useEffect, useState } from 'react';
import './tree.css'
import { CircularProgress, Grid } from '@mui/material';
import { getBinaryTreeData } from 'backendServices/ApiCalls';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Link, useParams } from 'react-router-dom';
import Div from '@jumbo/shared/Div/Div';
const BinaryTree = () => {

  const [userHierarchyData,setUserHierarchyData]=useState()
  const {loginUserData} = useContext(CustomProvider);
  const [loading,setLoading]=useState(true)

  const {randomcode} = useParams();
  let userData = loginUserData
  let userrandomcode = 'JKKJ62GJHF';
  if(randomcode)
  {
    userrandomcode = randomcode
  }
  const HierArchyData =()=>{
    setLoading(true)
    getBinaryTreeData(userrandomcode, (response) => {
      setUserHierarchyData(response?.data?.data)
      console.log("response?.data?.data",response?.data?.data) 
      setLoading(false)
      }, (error) => {
          console.log(error?.response?.data);
      })
  }
  
  useEffect(()=>{
    HierArchyData();
    console.log("useeffect")
  },[loginUserData, randomcode])
  

  if(loading){
    return  <Div
    sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
    }}
  >
    <CircularProgress sx={{m: '-40px auto 0'}}/>
  </Div>
  }

  return (
    <Grid container spacing={3.75}>
    <Grid item xs={12} md={6} lg={12}>
    <section
    className="management-hierarchy"
    id="tree"
    style={{ backgroundColor: "secondary" }}
  >
    <div className="hv-container">
      <div id="wrapper" className="hv-wrapper">
        {/* start */}
        {/* Key component */}
        <div id="container" className="hv-item custom_zoom">
          <div className="hv-item-parent">
            <Link to={'/binary-tree/'+userHierarchyData?.user1?.randomcode} style={{ textDecoration: 'none' }} className="person">
              <img src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png" alt="" style={{ marginBottom: 5, width: 80, height: 80 }} />
              <p
                className="name"
                style={{ backgroundColor: "#7352C7", color: "white" }}
              >
                {userHierarchyData?.user1?.username || 'empty'}
                <br />
                {userHierarchyData?.user1?.sponsorname}
              </p>
            </Link>
            
          </div>
          <div className="hv-item-children">
            <div className="hv-item-child">
              {/* Key component */}
              <div className="hv-item">
                <div className="hv-item-parent">
                 
                    <Link to={'/binary-tree/'+userHierarchyData?.user2?.randomcode} style={{ textDecoration: 'none' }} className="person">
                      <img src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png" style={{ marginBottom: 5, width: 80, height: 80 }} />
                      <p
                        className="name"
                        style={{ backgroundColor: "#7352C7", color: "white" }}
                      >
                {userHierarchyData?.user2?.username || 'empty'}
                <br />
                {userHierarchyData?.user2?.sponsorname}
                      </p>
                    </Link>
                </div>
                <div className="hv-item-children">
                  <div className="hv-item-child">
                    <div className="hv-item-child">
                      {/* Key component */}
                      <div className="hv-item">
                        <div className="">
                         
                            <Link to={'/binary-tree/'+userHierarchyData?.user4?.randomcode} style={{ textDecoration: 'none' }} className="person">
                              <img
                                src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                style={{ marginBottom: 5, width: 80, height: 80 }}
                              />
                              <p
                                className="name"
                                style={{
                                  backgroundColor: "#7352C7",
                                  color: "white"
                                }}
                              >
                {userHierarchyData?.user4?.username || 'empty'}
                <br />
                {userHierarchyData?.user4?.sponsorname}
                              </p>
                            </Link>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  <div className="hv-item-child">
                    <div className="hv-item-child">
                      {/* Key component */}
                      <div className="hv-item" style={{ marginBottom: 100 }}>
                        <div className="">
                          
                            <Link to={'/binary-tree/'+userHierarchyData?.user5?.randomcode} style={{ textDecoration: 'none' }} className="person">
                              <img
                                src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                style={{ marginBottom: 5, width: 80, height: 80 }}
                              />
                              <p
                                className="name"
                                style={{
                                  backgroundColor: "#7352C7",
                                  color: "white"
                                }}
                              >
                {userHierarchyData?.user5?.username || 'empty'}
                <br />
                {userHierarchyData?.user5?.sponsorname}
                              </p>
                            </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hv-item-child">
              {/* Key component */}
              <div className="hv-item">
                <div className="hv-item-parent">
                 
                    <Link to={'/binary-tree/'+userHierarchyData?.user3?.randomcode} style={{ textDecoration: 'none' }} className="person">
                      <img src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png" style={{ marginBottom: 5, width: 80, height: 80 }} />
                      <p
                        className="name"
                        style={{ backgroundColor: "#7352C7", color: "white" }}
                      >
                {userHierarchyData?.user3?.username || 'empty'}
                <br />
                {userHierarchyData?.user3?.sponsorname}
                      </p>
                    </Link>
                </div>
                <div className="hv-item-children">
                  <div className="hv-item-child">
                    <div className="hv-item-child">
                      {/* Key component */}
                      <div className="hv-item">
                        <div className="">
                         
                            <Link to={'/binary-tree/'+userHierarchyData?.user6?.randomcode} style={{ textDecoration: 'none' }} className="person">
                              <img
                                src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                style={{ marginBottom: 5, width: 80, height: 80 }}
                              />
                              <p
                                className="name"
                                style={{
                                  backgroundColor: "#7352C7",
                                  color: "white"
                                }}
                              >
                {userHierarchyData?.user6?.username || 'empty'}
                <br />
                {userHierarchyData?.user6?.sponsorname}
                              </p>
                            </Link>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="hv-item-child">
                    <div className="hv-item-child">
                      {/* Key component */}
                      <div className="hv-item">
                        <div className="">
                          
                            <Link to={'/binary-tree/'+userHierarchyData?.user7?.randomcode} style={{ textDecoration: 'none' }} className="person">
                              <img
                                src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                                style={{ marginBottom: 5, width: 80, height: 80 }}
                              />
                              <p
                                className="name"
                                style={{
                                  backgroundColor: "#7352C7",
                                  color: "white"
                                }}
                              >
                                {userHierarchyData?.user7?.username || 'empty'}
                                <br />
                                {userHierarchyData?.user7?.sponsorname}
                              </p>
                            </Link>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end */}
      </div>
    </div>
  </section>
    </Grid>
    </Grid>
   
  
  )}

export default BinaryTree;
