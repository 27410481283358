import React from "react";

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LogoutIcon from '@mui/icons-material/Logout';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PasswordIcon from '@mui/icons-material/Password';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { CurrencyExchange, PriceChange, Settings } from "@mui/icons-material";

const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>
            },
           
            // {
            //     uri: "/create-admin",
            //     label: 'sidebar.menuItem.createadmin',
            //     type: "nav-item",
            //     icon: <AdminPanelSettingsIcon sx={{fontSize: 20}}/>
            // },
            // {
            //     uri: "/manage-admin",
            //     label: 'sidebar.menuItem.manageadmin',
            //     type: "nav-item",
            //     icon: <AdminPanelSettingsIcon sx={{fontSize: 20}}/>
            // },
            {
                uri: "/active-users",
                label: 'sidebar.menuItem.activeusers',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/inactive-users",
                label: 'sidebar.menuItem.inactiveusers',
                type: "nav-item",
                icon: <GroupIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.usersdeposit',
                type: "collapsible",
                icon: <CurrencyExchange sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/pending-deposits",
                        label: "sidebar.menuItem.pendingdeposit",
                        type: "nav-item"
                    },
                    {
                        uri: "/approved-deposits",
                        label: "sidebar.menuItem.approveddeposit",
                        type: "nav-item"
                    },
    
                ]
            },

            {
                uri: "/change-sponsor",
                label: 'sidebar.menuItem.changesponsor',
                type: "nav-item",
                icon: <ManageAccountsIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.reports',
                type: "collapsible",
                icon: <GraphicEqIcon sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/unilevel-bonus",
                        label: 'sidebar.menuItem.unilevelbonus',
                        type: "nav-item",
                    },
                    {
                        uri: "/referral-bonus",
                        label: 'sidebar.menuItem.referralbonus',
                        type: "nav-item",
                    },
                    {
                        uri: "/roi-summary",
                        label: 'sidebar.menuItem.roisummary',
                        type: "nav-item",
                    },
   
                ]
            },

            {
                label: 'sidebar.menuItem.payout',
                type: "collapsible",
                icon: <PriceChange sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/pending-payout",
                        label: "sidebar.menuItem.pendingpayout",
                        type: "nav-item"
                    },
                    {
                        uri: "/approved-payout",
                        label: "sidebar.menuItem.approvedpayout",
                        type: "nav-item"
                    },
                    {
                        uri: "/rejected-payout",
                        label: "sidebar.menuItem.rejectedpayout",
                        type: "nav-item"
                    },      
                ]
            },
            // {
            //     uri: "/payment-method",
            //     label: 'sidebar.menuItem.updatepayment',
            //     type: "nav-item",
            //     icon: <AccountBalanceIcon sx={{fontSize: 20}}/>
            // },
      
            {
                uri: "/binary-tree",
                label: 'sidebar.menuItem.tree',
                type: "nav-item",
                icon: <ReduceCapacityIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/profile",
                label: 'sidebar.menuItem.updatepassword',
                type: "nav-item",
                icon: <PasswordIcon sx={{fontSize: 20}}/>
            },
            {
                label: 'sidebar.menuItem.settings',
                type: "collapsible",
                icon: <Settings sx={{fontSize: 20}}/>,
                children: [
                    {
                        uri: "/update-unilevel-bonus",
                        label: "sidebar.menuItem.updateunilevel",
                        type: "nav-item"
                    },
                    {
                        uri: "/update-payout",
                        label: "sidebar.menuItem.updatepayout",
                        type: "nav-item"
                    },
                    {
                        uri: "/update-admin-wallet",
                        label: "sidebar.menuItem.depositwalletsetting",
                        type: "nav-item"
                    },
                         
                ]
            },
            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{fontSize: 20}}/>
            },

            
        ]
    },
  
];

export default menus;
