import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {payoutaction, payoutsummary } from 'backendServices/ApiCalls';
import { Chip,IconButton, Tooltip, Grid } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CancelIcon from '@mui/icons-material/Cancel';

const VISIBLE_FIELDS = ['sr','receiverusername', 'amount', 'payoutaccount1','payoutaccount2', 'approvedat', 'rejectreason'];



const RejectedPayout = () => {
  const [copiedRows, setCopiedRows] = useState([]);
  const [payoutdata,setPayoutData]=useState([])
  const PayoutData =()=>{
    let params = {
      status : 'rejected'
    }
    payoutsummary(params,(response) => {
      if(response?.data?.status === "success") {
          setPayoutData(response?.data?.data?.enteries)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
    
useEffect(()=>{
    console.log('useeffect')
    PayoutData();
},[])

const generateRowId = () => {
  return `row-${Math.random().toString(36).substr(2, 9)}`;
};



const rows= payoutdata
  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Rejected Payouts'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'receiverusername') {
                return {
                  field,
                  headerName: 'User',
                  width: 150,
                };
              } else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 150,
                  renderCell: (params) => `$${params.value}`
                };
              } else if (field === 'status') {
                return {
                  field,
                  headerName: 'Status',
                  width: 150,
                  renderCell: (params) => (
                    <Chip
                      label={params.value}
                      color={params.value === 'Pending' ? 'default' : 'primary'}
                      size="small"
                    />
                  ),
                };
              } else if (field === 'approvedat') {
                return {
                  field,
                  headerName: 'Reject Date',
                  width: 200,
                };
              }
              else if (field === 'payoutaccount1') {
                return {
                  field,
                  headerName: 'Method',
                  width: 80,
                };
              }
              else if (field === 'rejectreason') {
                return {
                  field,
                  headerName: 'Reason',
                  width: 200,
                };
              }
              else if (field === 'payoutaccount2') {
                return {
                  field,
                  headerName: 'Address',
                  width: 200,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);
                    
                    const handleCopyClick = () => {
                      navigator.clipboard.writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                        })
                        .catch((error) => {
                          console.error('Copy failed:', error);
                        });
                    };
          
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isCopied ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : (
                          <Tooltip title="Copy Hash" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              }
              else{
              return {
                field,
                headerName: field,
                width: 150,
              };
            }
            }),
            
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default RejectedPayout;
