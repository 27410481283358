import React from "react";
import Crypto from "app/pages/dashboard/Crypto/Crypto";
import Page from "@jumbo/shared/Page";
import Login from "app/pages/auth-pages/login/Login";
import UserProfile from "app/pages/users/user-profile/UserProfile";
import UnilevelReport from "app/pages/reports/unilevel/UnilevelReport";
import ReferralBonusReport from "app/pages/reports/refferal/ReferralBonusReport";
import DepositReport from "app/pages/reports/deposit/DepositReport";
import PendingDeposit from "app/pages/reports/deposit/PendingDeposit";
import Logout from "app/pages/auth-pages/logout/Logout";
import PayoutReports from "app/pages/reports/payout/PayoutReports";
import ApprovedPayout from "app/pages/reports/payout/AprovedPayout";
import RoiReport from "app/pages/reports/roi/roi";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import BinaryTree from "app/pages/treeview/BinaryTree";
import faq from "app/pages/faq/faq";
import PaymentMethod from "app/pages/paymentmethod/method";
import Active from "app/pages/users/active";
import Inactive from "app/pages/users/inactive";
import CreateAdmin from "app/pages/mini-admin/create/createadmin";
import ManageAdmin from "app/pages/mini-admin/manage/manageadmin";
import Error404 from "app/pages/Error404/Error404";
import UpdateSponsor from "app/pages/updatesponsor/updatesponsor";
import Unilevelbonus from "app/pages/settings/unilevelbonus";
import DepositWallet from "app/pages/settings/DepositWallet";
import PayoutSettings from "app/pages/settings/PayoutSettings";
import ApprovedDeposit from "app/pages/reports/deposit/ApprovedDeposit";
import RejectedPayout from "app/pages/reports/payout/RejectedPayout";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [



];

/**
 routes only accessible to authenticated users
 **/

const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Crypto} />
    },
    {
        path: "/dashboard",
        element: <Page component={Crypto} />
    },
    {
        path: "/create-admin",
        element: <Page component={CreateAdmin} />
    }, 
    {
        path: "/manage-admin",
        element: <Page component={ManageAdmin} />
    },  
    {
        path: "/active-users",
        element: <Page component={Active} />
    },  
    {
        path: "/inactive-users",
        element: <Page component={Inactive} />
    },
    {
        path: "/pending-deposits",
        element: <Page component={PendingDeposit} />
    },
    {
        path: "/approved-deposits",
        element: <Page component={ApprovedDeposit} />
    },    
    {
        path: "/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/unilevel-bonus",
        element: <Page component={UnilevelReport} />
    },
    {
        path: "/referral-bonus",
        element: <Page component={ReferralBonusReport} />
    },
    {
        path: "/pending-payout",
        element: <Page component={PayoutReports} />
    },
    {
        path: "/approved-payout",
        element: <Page component={ApprovedPayout} />
    },
    {
        path: "/rejected-payout",
        element: <Page component={RejectedPayout} />
    },
    {
        path: "/payment-method",
        element: <Page component={PaymentMethod} />
    },
    {
        path: "/donation-summary",
        element: <Page component={DepositReport} />
    },
    {
        path: "/roi-summary",
        element: <Page component={RoiReport} />
    },
    {
        path: "/binary-tree",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/binary-tree/:randomcode",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/faq",
        element: <Page component={faq} />
    },
    {
        path: "/logout",
        element: <Page component={Logout} />
    },
    {
        path: "/update-unilevel-bonus",
        element: <Page component={Unilevelbonus}  />
    },
    {
        path: "/update-payout",
        element: <Page component={PayoutSettings}  />
    },
    {
        path: "/update-admin-wallet",
        element: <Page component={DepositWallet}  />
    },
    {
        path: "/404",
        element: <Page component={Error404}  />
    },
];

/**
 routes only accessible when user is anonymous
 **/
 const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login}  />
    },
    {
        path: "/login/:token/:email",
        element: <Page component={Login}  />
    },
    
    {
        path: "/forget-password",
        element: <Page component={ForgotPassword}  />
    },
    {
        path: "/reset-password/:token/:email",
        element: <Page component={ResetPassword}  />
    },
 ];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};