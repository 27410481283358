import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {getusers } from 'backendServices/ApiCalls';
import {Link} from "react-router-dom";
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';

const generateRowId = () => {
  // Generate a random string or use any other logic to create a unique ID
  return `row-${Math.random().toString(36).substr(2, 9)}`;
};


const columns = [
  {
    field: "id",
    headerName: "Sr#",
    width: 50,
    editable: true,
    groupable: false,
    aggregable: false,
    valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist

  },
{
  field: "username",
  headerName: "Username",  
  dataGeneratorUniquenessEnabled: true,
  width: 150,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "fullname", // New column for Full Name
  headerName: "Full Name",
  dataGeneratorUniquenessEnabled: true,
  width: 200,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "email",
  headerName: "email",  
  dataGeneratorUniquenessEnabled: true,
  width: 200,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "country",
  headerName: "Country",  
  dataGeneratorUniquenessEnabled: true,
  width: 150,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "mobile",
  headerName: "Mobile",  
  dataGeneratorUniquenessEnabled: true,
  width: 100,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "walletbalance",
  headerName: "Balance",  
  dataGeneratorUniquenessEnabled: true,
  width: 80,
  editable: true,
  groupable: false,
  aggregable: false,
},

{
  field: "totalearning",
  headerName: "Earning",  
  dataGeneratorUniquenessEnabled: true,
  width: 80,
  editable: true,
  groupable: false,
  aggregable: false,
},

// {
//   field: "totalteam",
//   headerName: "Team",  
//   dataGeneratorUniquenessEnabled: true,
//   width: 80,
//   editable: true,
//   groupable: false,
//   aggregable: false,
// },
// {
//   field: "membership",
//   headerName: "Membership",  
//   dataGeneratorUniquenessEnabled: true,
//   width: 120,
//   editable: true,
//   groupable: false,
//   aggregable: false,
// },


// {
//   field: "viewTree",
//   headerName: "View Tree",
//   width: 120,
//   editable: true,
//   groupable: false,
//   aggregable: false,
//   renderCell: (params) => {
//     const randomcode = params?.row?.randomcode;

//     const tree = '/binary-tree/'+randomcode;
//     return <Link to={tree}><ReduceCapacityIcon sx={{fontSize: 20}}/></Link>
//   //   return <IconButton component="Link" to={tree}>
//   //   <DeleteIcon />
//   // </IconButton>
//   }
 
// },


]

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
    }
}
}
}



const VISIBLE_FIELDS = ['sr', 'amount', 'status','date'];
const Active = () => {
  
  
  const [usersdata,setUsersData]=useState([])
  let params = {
    status:'approved',
  }
  const UsersData =()=>{
    getusers(params,(response) => {
      if(response?.data?.status === "success") {
          setUsersData(response?.data?.data?.enteries)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }


  console.log("usersdata",usersdata) 
    
useEffect(()=>{
    console.log('useeffect')
    UsersData();
},[])



const rows= usersdata

let idCounter = 1; // Initialize the counter

// Assign a unique sequential ID to each row
const rowsWithId = rows.map((row) => ({
  ...row,
  id: idCounter++, // Assign the current counter value and then increment it
  fullname: row.firstname + ' ' + row.lastname,
}));
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
      color: '#fff',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
   
}

  return (
    <JumboDemoCard
    title={"Active Users"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>

    <Box sx={{ height: 800, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        rows={rowsWithId}
       
        getRowId={(row) => generateRowId()} 
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[15, 30, 75, 100]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default Active