import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { useState } from 'react';
import {  getsettingsdata, updateProfilePicture, updatesetting } from 'backendServices/ApiCalls';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import { Form, Formik } from "formik";
import Div from '@jumbo/shared/Div/Div';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { CircularProgress } from '@mui/material';


const DepositWallet = () => {
    const [levelbonusdata, setLevelBonusData] = useState('');
    const [isloading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({

    });
    const GetSettingsData = () => {
        let params = {
            keynames : "'walletqrcode','walletaddress','walletmessage'"
        }

        getsettingsdata(params,(response) => {
            setLevelBonusData(response?.data)
            setIsLoading(false);
            
            const initialData = {};
            response?.data?.data?.bonus.forEach((item) => {
              initialData[item.keyname] = item.keyvalue;
            });
            setInitialValues(initialData);
          
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    useEffect(() => {
        GetSettingsData();
    }, []);


    const validationSchema = yup.object({
        walletaddress: yup
            .string()
            .required('Address  is required'),
        walletmessage: yup
            .string()
            .required('Message  is required'),
    });


    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: ""
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const [image, setImage] = useState(null);
    const [isBtnLoading, setBtnIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const style = {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "black"
            }
        }
    }


    const handleUpload = (e) => {
        if (e.target.files && e.target.files.length > 0) {
           const selectedFile = e.target.files[0];
            if (selectedFile.type.startsWith("image/")) {
                setImage(selectedFile);
              } else {
                setalertData({
                    show:true,
                    message:'Invalid file type. Please select an image file.',
                    variant:"error"
                })
              }
        }
    }

    const handleSubmit = (data, setSubmitting) => {
        let params = {
          obj: {
            walletaddress: data.walletaddress,
            walletmessage: data.walletmessage
          }
        };
      
        if (!image) {
          setalertData({
            show: true,
            message: 'Invalid file type. Please select an image file.',
            variant: 'error'
          });
          setSubmitting(false);
          return;
        }
    
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          const formData = new FormData();
          formData.append('image', base64Data);
          formData.append('walletaddress', data.walletaddress);
          formData.append('walletmessage', data.walletmessage);
      
          updatesetting(formData, (response) => {
            setSubmitting(false);
      
            if (response?.data?.status === 'error') {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: 'error'
              });
            } else if (response?.data?.status === 'success') {
                setLevelBonusData((prevState) => ({
                    ...prevState,
                    picturelink: response?.data?.qrpictureurl,
                  }));
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: 'success'
              });
              // Additional logic after successful submission
            } else {
              setalertData({
                show: true,
                message: 'Something went wrong. Please try again later.',
                variant: 'error'
              });
            }
          }, (error) => {
            setSubmitting(false);
            setalertData({
              show: true,
              message: 'Something went wrong. Please try again.',
              variant: 'error'
            });
          });
        };
      
        reader.readAsDataURL(image);
      };
      


    if (isloading) {
        return  <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
      >
        <CircularProgress sx={{m: '-40px auto 0'}}/>
      </Div>
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <JumboCardQuick title={"Deposit Wallet Settings"} noWrapper sx={{maxWidth: '100%', width: 360, mb: 4}}>

            {
                alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
            }
            <List disablePadding sx={{ mb: 2 }}>
                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2, textAlign:'center' }} >
                    <Typography variant='h4'>Current Qr Image</Typography>
                    <img src={levelbonusdata?.picturelink} style={{width:'100px'}} />
                </Div>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        handleSubmit(data, setSubmitting);
                    }}
                >

                    {({ isSubmitting }) => (
                        <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>

                        {levelbonusdata?.data?.bonus?.map((data, i) => {

                            if (data.keyname === 'walletqrcode') {
                                return (
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }} key={i}>
                                 
                                        <TextField
                                        onChange={handleUpload}
                                        name='file'
                                        type='file'
                                        label='Update Image'
                                        margin="normal"
                                        InputProps={{endAdornment: 
                                            <CameraAltIcon fontSize={"small"} color={"success"}/>
                                        }}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                
                                        />
                                    </Div>
                            
                                );
                            }
                            else{

                                return (
                                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }} key={i}>
                                        <JumboTextField
                                            fullWidth
                                            name={data.keyname}
                                            label={data.keyname}
                                            defaultValue={data?.keyvalue}
                                            type='text'
                                        />
                                    </Div>
                                );
                            }
                        })}



                            <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 3 }}
                                    loading={isSubmitting}
                                >Update</LoadingButton>
                            </Div>



                        </Form>
                    )}
                </Formik>

            </List>
        </JumboCardQuick>
        </div>
    );
};

export default DepositWallet;
