import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {referralbonusreport } from 'backendServices/ApiCalls';


const VISIBLE_FIELDS = ['sr','senderusername','receiverusername', 'amount', 'createdat'];



const ReferralBonusReport = () => {
  const [referralbonusdata,setReferralBonusData]=useState([])
  const ReferralData =()=>{
    referralbonusreport((response) => {
      if(response?.data?.status === "success") {
          setReferralBonusData(response?.data?.data?.enteries)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
    
useEffect(()=>{
    console.log('useeffect')
    ReferralData();
},[])

const generateRowId = () => {
  return `row-${Math.random().toString(36).substr(2, 9)}`;
};



const rows= referralbonusdata
  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Referral Bonus Report'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'senderusername') {
                return {
                  field,
                  headerName: 'Sender',
                  width: 150,
                };
              }else if (field === 'receiverusername') {
                return {
                  field,
                  headerName: 'Receiver',
                  width: 150,
                };
              } else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 150,
                  renderCell: (params) => `$${params.value}`
                };
              }  else if (field === 'createdat') {
                return {
                  field,
                  headerName: 'Date',
                  width: 200,
                };
              }
             
              else{
              return {
                field,
                headerName: field,
                width: 150,
              };
            }
            }),
            
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default ReferralBonusReport;
