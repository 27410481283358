import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {payoutaction, payoutsummary } from 'backendServices/ApiCalls';
import { Chip,IconButton, Tooltip, Grid } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CancelIcon from '@mui/icons-material/Cancel';

const VISIBLE_FIELDS = ['sr','receiverusername', 'amount', 'payoutaccount1','payoutaccount2', 'createdat'];

const useSweetAlert = (PayoutData) => {
  const [alertData, setAlertData] = useState({
    show: false,
    message: '',
    variant: '',
    confirmFunction: null,
    action: '',
  });

  const handleApprove = (id, amount) => {

    return new Promise((resolve) => {
      let params = {
        action: 'approve',
        tid: id,
      };

      payoutaction(
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          Swal.fire({
            title: 'Payout Approved',
            text: 'Payout approved successfully',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            setAlertData((prevAlertData) => ({
              ...prevAlertData,
              show: false,
            }));
          });
          PayoutData();
          resolve()
        }
      },
      (error) => {
        console.log(error?.response?.data);
        resolve()
      }
    );
    })
  };

  const handleReject = (id, amount, reason) => {

    return new Promise((resolve) => {
      let params = {
        action : 'reject',
        tid: id,
        reason: reason,
      };

      payoutaction(
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          Swal.fire({
            title: 'Payout Rejected',
            text: 'with reason "'+reason+'"',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then(() => {
            setAlertData((prevAlertData) => ({
              ...prevAlertData,
              show: false,
            }));
          });
          PayoutData();
          resolve()
        }
      },
      (error) => {
        console.log(error?.response?.data);
        resolve()
      }
    );
    })
  };

  const showSweetAlert = (title, description, confirmFunction, variant, action) => {
    setAlertData({
      show: true,
      message: description,
      variant: variant,
      confirmFunction: confirmFunction,
      action: action,
    });
  };

  useEffect(() => {
    if (alertData.show) {
      const hasTextField = alertData.action === 'reject';
      Swal.fire({
        title: alertData.title,
        html: `<div>${alertData.message}</div>${hasTextField ? '<input type="text" id="reason-input" class="swal2-input" placeholder="Reason for rejection" required="">' : ''}`,
        text: alertData.message,
        icon: alertData.variant,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return new Promise(async (resolve) => {
            if (hasTextField) {
              const reason = document.getElementById('reason-input').value;
              if (!reason) {
                Swal.showValidationMessage('Please enter a reason for rejection');
                resolve(false);
              } else {
                await alertData.confirmFunction(reason);
                resolve();
              }
            } else {
              await alertData.confirmFunction();
              resolve();
            }
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          if (hasTextField) {
            const input = document.getElementById('reason-input');
            if (input) {
              input.focus();
            }
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the case when "Yes" is clicked
        } else {
          // Handle the case when "No" is clicked
        }
      });
    }
  }, [alertData]);

  return {
    showSweetAlert,
    handleApprove,
    handleReject,
  };
};


const PayoutReports = () => {
  const [copiedRows, setCopiedRows] = useState([]);
  const [payoutdata,setPayoutData]=useState([])
  const PayoutData =()=>{
    let params = {
      status : 'pending'
    }
    payoutsummary(params,(response) => {
      if(response?.data?.status === "success") {
          setPayoutData(response?.data?.data?.enteries)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
    
useEffect(()=>{
    console.log('useeffect')
    PayoutData();
},[])

const generateRowId = () => {
  return `row-${Math.random().toString(36).substr(2, 9)}`;
};


const { showSweetAlert, handleApprove, handleReject } = useSweetAlert(PayoutData);

const handleActionClick = (id,amount, action) => {
  let title = '';
  let description = '';
  let confirmFunction = null;
  let variant = ''

  if (action === 'approve') {
    title = 'Approve Payout';
    description = 'Are you sure you want to approve this payout?';
    confirmFunction = ()=>handleApprove(id,amount);
    variant = 'warning';
  } else if (action === 'reject') {
    title = 'Reject Payout';
    description = 'Are you sure you want to reject this payout?';
    confirmFunction = (reason) => handleReject(id, amount, reason);
    variant = 'error';
  }

  showSweetAlert(title, description, confirmFunction,variant,action);
};


const rows= payoutdata
  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#fff',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };



  return (
    <JumboDemoCard title={'Pending Payouts'} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState: {
              columns: {
                columnVisibilityModel: {
                  id: false,
                  avatar: false,
                  website: false,
                  email: false,
                  phone: false,
                  username: false,
                  city: false,
                  company: false,
                  position: false,
                  lastUpdated: false,
                  salary: false,
                },
              },
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={[
            ...VISIBLE_FIELDS.map((field) => {
              if (field === 'sr') {
                return {
                  field,
                  headerName: 'Sr',
                  width: 150,
                  renderCell: (params) => <strong>{params.row.id}</strong>,
                };
              } else if (field === 'receiverusername') {
                return {
                  field,
                  headerName: 'User',
                  width: 150,
                };
              } else if (field === 'amount') {
                return {
                  field,
                  headerName: 'Amount',
                  width: 150,
                  renderCell: (params) => `$${params.value}`
                };
              } else if (field === 'status') {
                return {
                  field,
                  headerName: 'Status',
                  width: 150,
                  renderCell: (params) => (
                    <Chip
                      label={params.value}
                      color={params.value === 'Pending' ? 'default' : 'primary'}
                      size="small"
                    />
                  ),
                };
              } else if (field === 'createdat') {
                return {
                  field,
                  headerName: 'Date',
                  width: 150,
                };
              }
              else if (field === 'payoutaccount1') {
                return {
                  field,
                  headerName: 'Method',
                  width: 80,
                };
              }
              else if (field === 'payoutaccount2') {
                return {
                  field,
                  headerName: 'Address',
                  width: 200,
                  renderCell: (params) => {
                    const isCopied = copiedRows.includes(params.row.id);
                    
                    const handleCopyClick = () => {
                      navigator.clipboard.writeText(params.value)
                        .then(() => {
                          setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                        })
                        .catch((error) => {
                          console.error('Copy failed:', error);
                        });
                    };
          
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isCopied ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : (
                          <Tooltip title="Copy Hash" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <span>{params.value}</span>
                      </div>
                    );
                  },
                };
              }
              else{
              return {
                field,
                headerName: field,
                width: 150,
              };
            }
            }),
            {
              field: 'actions',
              headerName: 'Actions',
              width: 120,
              renderCell: (params) => (
                <>
                  <Tooltip title="Approve" placement="top">
                    <IconButton
                      onClick={() => handleActionClick(params.row.tid,params.row.amount, 'approve')}
                      sx={{ color: 'success.main' }}
                    >
                      <AddTaskIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reject" placement="top">
                    <IconButton
                      onClick={() => handleActionClick(params.row.tid,params.row.amount, 'reject')}
                      sx={{ color: 'error.main' }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            },
            
          ]}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default PayoutReports;
